<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 1400px;height: 1000px">
        <el-cascader :options="options" :show-all-levels="true" v-model="this.type" placeholder="选择领域" @change="this.getInf"/>
        <el-button type="primary" @click="this.$router.push({path:'/SuCaseAdd',query: {sid: subform.sid}})">添加案例</el-button>
        <el-table
            :data="info"
            highlight-current-row
            max-height="1000"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="封面">
            <template #default="scope">
              <el-image :src="scope.row.pic"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="案例名称" prop="caseName"></el-table-column>
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button @click="this.$router.push({path:'/CaseDetail',query: {caseId: scope.row.id}})">查看</el-button>
              <el-button type="danger" @click="DeleteCase(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "AllCase",
  data(){
    return{
      options:[
        {
          value: '产品设计',
          label: '产品设计',
          children: [
            {
              value: '',
              label: '全部',
            },
            {
              value: '医疗设备',
              label: '医疗设备',
            },
            {
              value: '仪器设备',
              label: '仪器设备',
            },
            {
              value: '消费电子',
              label: '消费电子',
            },
            {
              value: '交通出行',
              label: '交通出行',
            },
            {
              value: '工业设备',
              label: '工业设备',
            },
            {
              value: '机器人',
              label: '机器人',
            },]
        },
        {
          value: '研发工程',
          label: '研发工程',
          children: [
            {
              value: '',
              label: '全部',
            },
            {
              value: '结构设计',
              label: '结构设计',
            },
            {
              value: '自动化设计',
              label: '自动化设计',
            },]
        },
        {
          value: '传播设计',
          label: '传播设计',
          children: [
            {
              value: '',
              label: '全部',
            },
            {
              value: '品牌vi',
              label: '品牌vi',
            },
            {
              value: '电商视觉',
              label: '电商视觉',
            },
            {
              value: '网站/APP',
              label: '网站/APP',
            },
            {
              value: '产品包装',
              label: '产品包装',
            },
            {
              value: 'Logo设计',
              label: 'Logo设计',
            },
            {
              value: '画册设计',
              label: '画册设计',
            },
            {
              value: 'UI设计',
              label: 'UI设计',
            },]
        },
        {
          value: '空间设计',
          label: '空间设计',
          children: [
            {
              value: '',
              label: '全部',
            },
            {
              value: '商业空间',
              label: '商业空间',
            },
            {
              value: '厂房改造',
              label: '厂房改造',
            },
            {
              value: '会展陈列',
              label: '会展陈列',
            },
            {
              value: '空间导视',
              label: '空间导视',
            },]
        }
      ],

      type:['产品设计','全部'],

      subform:{
        sid:'',
        currentPage:1,
        pageSize: 20,
        typeFirst:'',
        typeSecond:'',
      },

      deleteFrom:{
        caseId:'',
      },

      info:[],
      total: 0,

    }
  },
  mounted() {
    this.subform.sid = parseInt(this.$route.query.sid);
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/getAllCasesForSupplier',
        data: this.subform,
      }).then(res => {
        this.info = res.data.data;
        this.total = res.data.count;
        this.subform.currentPage = res.data.currentPage;
      })
    },

    DeleteCase(index){
      this.deleteFrom.caseId = index;
      ElMessageBox.confirm(
          '该操作会删除该案例且不可恢复',
          '您正在进行删除案例的操作',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.$http({
              method: 'post',
              url: '/deleteCase',
              data: this.deleteFrom,
            }).then(res => {
              if (res.data.code === 1) {
                ElMessage({
                  message: '删除成功',
                  type: 'success',
                })
                this.getInf();
              } else {
                ElMessage({
                  message: '删除失败,' + res.data.message,
                  type: 'warning',
                })
              }
            })
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '删除操作已被取消',
            })
          })
    },

  }
}
</script>

<style scoped>
.bac{
  height: 1194px;
}
</style>